import React, { useCallback } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import navlinks from "../../../utils/models/navlinks";

const HeaderMainBox = ({ handleShowLogin, handleFindJobsClick }) => {
  const navigate = useNavigate();

  /* HANDLE JOB POST CLICK */
  const handleJobPostClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="main-box">
      <div className="nav-outer">
        <div className="logo-box">
          <div className="logo">
            <Link to="/">
              <img src="images/logo.png" alt="" title="" />
            </Link>
          </div>
        </div>

        <nav className="nav main-menu">
          <ul className="navigation nav-lins-btns" id="navbar">
            {navlinks.map((nav, index) => (
              <li key={index}>
                <NavLink
                  to={nav.path}
                  className={({ isActive }) => (isActive ? "current" : "")}
                  onClick={() => handleFindJobsClick(nav?.action)}
                >
                  {nav.page}
                </NavLink>
              </li>
            ))}

            {/* UNCOMMENT WHEN IT IS NEEDED */}
            {/* <li className="dropdown">
              <span>Employers</span>
              <ul>
                <li className="dropdown">
                  <span>Employers List</span>
                  <ul>
                    <li>
                      <a href="employers-list-v1.html">Employers LIst v1</a>
                    </li>
                    <li>
                      <a href="employers-list-v2.html">Employers LIst v2</a>
                    </li>
                    <li>
                      <a href="employers-list-v3.html">Employers LIst v3</a>
                    </li>
                    <li>
                      <a href="employers-list-v4.html">Employers LIst v4</a>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <span>Employers Single</span>
                  <ul>
                    <li>
                      <a href="employers-single-v1.html">Employers Single v1</a>
                    </li>
                    <li>
                      <a href="employers-single-v2.html">Employers Single v2</a>
                    </li>
                    <li>
                      <a href="employers-single-v3.html">Employers Single v3</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="dashboard.html">Employers Dashboard</a>
                </li>
              </ul>
            </li> */}
            {/* <li className="dropdown">
              <span>Candidates</span>
              <ul>
                <li className="dropdown">
                  <span>Candidates List</span>
                  <ul>
                    <li>
                      <a href="candidates-list-v1.html">Candidates LIst v1</a>
                    </li>
                    <li>
                      <a href="candidates-list-v2.html">Candidates LIst v2</a>
                    </li>
                    <li>
                      <a href="candidates-list-v3.html">Candidates LIst v3</a>
                    </li>
                    <li>
                      <a href="candidates-list-v4.html">Candidates LIst v4</a>
                    </li>
                    <li>
                      <a href="candidates-list-v5.html">Candidates LIst v5</a>
                    </li>
                    <li>
                      <a href="candidates-list-v6.html">Candidates LIst v6</a>
                    </li>
                    <li>
                      <a href="candidates-list-v7.html">Candidates LIst v7</a>
                    </li>
                  </ul>
                </li>

                <li className="dropdown">
                  <span>Candidates Single</span>
                  <ul>
                    <li>
                      <a href="candidates-single-v1.html">
                        Candidates Single v1
                      </a>
                    </li>
                    <li>
                      <a href="candidates-single-v2.html">
                        Candidates Single v2
                      </a>
                    </li>
                    <li>
                      <a href="candidates-single-v3.html">
                        Candidates Single v3
                      </a>
                    </li>
                    <li>
                      <a href="candidates-single-v4.html">
                        Candidates Single v4
                      </a>
                    </li>
                    <li>
                      <a href="candidates-single-v5.html">
                        Candidates Single v5
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="candidate-dashboard.html">Candidates Dashboard</a>
                </li>
              </ul>
            </li> */}
            {/* <li className="dropdown">
              <span>Blog</span>
              <ul>
                <li>
                  <a href="blog-list-v1.html">Blog LIst v1</a>
                </li>
                <li>
                  <a href="blog-list-v2.html">Blog LIst v2</a>
                </li>
                <li>
                  <a href="blog-list-v3.html">Blog LIst v3</a>
                </li>
                <li>
                  <a href="blog-single.html">Blog Single</a>
                </li>
              </ul>
            </li> */}

            {/* <li className="dropdown">
              <span>Pages</span>
              <ul>
                <li className="dropdown">
                  <span>Shop</span>
                  <ul>
                    <li>
                      <a href="shop.html">Shop List</a>
                    </li>
                    <li>
                      <a href="shop-single.html">Shop Single</a>
                    </li>
                    <li>
                      <a href="shopping-cart.html">Shopping Cart</a>
                    </li>
                    <li>
                      <a href="shop-checkout.html">Checkout</a>
                    </li>
                    <li>
                      <a href="order-completed.html">Order Completed</a>
                    </li>
                    <li>
                      <a href="login.html">Login</a>
                    </li>
                    <li>
                      <a href="register.html">Register</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="about.html">About</a>
                </li>
                <li>
                  <a href="pricing.html">Pricing</a>
                </li>
                <li>
                  <a href="faqs.html">FAQ's</a>
                </li>
                <li>
                  <a href="terms.html">Terms</a>
                </li>
                <li>
                  <a href="invoice.html">Invoice</a>
                </li>
                <li>
                  <a href="elements.html">Ui Elements</a>
                </li>
                <li>
                  <a href="contact.html">Contact</a>
                </li>
              </ul>
            </li> */}

            <li className="mm-add-listing">
              <a href="add-listing.html" className="theme-btn btn-style-one">
                Job Post
              </a>
              <span>
                <span className="contact-info">
                  <span className="phone-num">
                    <span>Call us</span>
                    <a href="tel:1234567890">123 456 7890</a>
                  </span>
                  <span className="address">
                    329 Queensberry Street, North Melbourne VIC <br />
                    3051, Australia.
                  </span>
                  <a href="mailto:support@superio.com" className="email">
                    support@superio.com
                  </a>
                </span>
                <span className="social-links">
                  <a href="#">
                    <span className="fab fa-facebook-f"></span>
                  </a>
                  <a href="#">
                    <span className="fab fa-twitter"></span>
                  </a>
                  <a href="#">
                    <span className="fab fa-instagram"></span>
                  </a>
                  <a href="#">
                    <span className="fab fa-linkedin-in"></span>
                  </a>
                </span>
              </span>
            </li>
          </ul>
        </nav>
      </div>

      <div className="outer-box">
        {/* <p href="candidate-dashboard-cv-manager.html" className="upload-cv">
          {" "}
          Upload your CV
        </p> */}
        <div className="btn-box gap-3">
          <button
            type="button"
            onClick={handleShowLogin}
            className="theme-btn btn-style-three call-modal cursor-pointer"
          >
            Login / Register
          </button>
          {/* <button
            type="button"
            onClick={handleJobPostClick}
            className="theme-btn btn-style-one cursor-pointer"
          >
            Job Post
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderMainBox;
